<template>
    <div>
        <emc-chart-apex-base v-if="renderComponent" :series="series" :type="type" :options="chartOptions" :loading.sync="loading" @refresh="loadData()" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: 'ChartHybridity',
    data (vm) {
        return {
            loading: false,
            renderComponent: true,
            series: [],
            type: null,
            chartOptions: {
                chart: {},
                labels: [],
                title: {},
                colors: [],
                legend: {
                    position: 'right'
                }
            },
        }
    },
    props: {
       filter: {
            required: true,
       },
    },
    methods: {
        ...mapActions('grid', ['ActionOfferItemsIndicatorHybridity']),

        loadData(){

            this.loading = true;
            
            this.ActionOfferItemsIndicatorHybridity(this.filter)
            .then((res) => {
                
                this.series = res.data.series;
                this.type = res.data.type;
                this.chartOptions.title.text = res.data.title;
                this.chartOptions.labels = res.data.labels;
                
            }).catch((error) =>{
                
            })
            .finally(() => {
                this.loading = false;
                this.$emit('loaded');
            });

        },
        
    },
    
}
</script>